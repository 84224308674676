<template>
  <div class="bk-page" id="crmOrder">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form
          class="bk-page-search"
          @submit.native.prevent
          inline
          :label-width="80"
        >
          <FormItem label="单据编号">
            <Input v-model="searchData1.OrderCode" clearable />
          </FormItem>
          <FormItem label="合同编号">
            <Input v-model="searchData1.ContractCode" clearable />
          </FormItem>
          <FormItem label="状态">
            <Select v-model="searchData1.Status" clearable>
              <Option
                v-for="(a, i) in $designConst.crmOrder.StatusLst"
                :key="i"
                :value="a.key"
                >{{ a.name }}</Option
              >
            </Select>
          </FormItem>
          <FormItem class="btns">
            <Button type="primary" @click="onSearch">搜索</Button>
            <Button type="default" @click="reSearchData">重置</Button>
            <Button type="primary" ghost @click="toAdd" v-if="$able.map.CrmOrderAdd">
              <Icon type="md-add" />新增</Button
            >
          </FormItem>
        </Form>
        <Table
          :loading="tableLoading"
          :columns="tableCols"
          :data="tableData"
        ></Table>
        <Page
          :total="recordCount"
          :current="pageIndex"
          :page-size="pageSize"
          transfer
          show-total
          show-sizer
          show-elevator
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
        />
      </div>
    </div>
    <edit ref="edit" @on-add="onChangePage(1)" @on-upd="reqTable" />
  </div>
</template>
<script>
import edit from "./order-edit.vue";
export default {
  components: { edit },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.crmOrder.StatusBadge[params.row.Status],
                text: this.$designConst.crmOrder.Status[params.row.Status],
              },
            });
          },
        },
        { title: "业务员", key: "SalesName" },
        { title: "客户", key: "ClientName" },
        {
          title: "跟单开始日期",
          render: (h, params) =>
            h(
              "span",
              params.row.OrderDate
                ? params.row.OrderDate.substring(0, 10)
                : "--"
            ),
        },
        { title: "单据编号", key: "OrderCode" },
        { title: "合同编号", key: "ContractCode" },
        {
          title: "项目评估",
          render: (h, params) => {
            return h(
              "span",
              params.row.Isevaluate == 1
                ? params.row.EvaluateTime.substring(0, 10)
                : "未评估"
            );
          },
        },
        {
          title: "报价",
          render: (h, params) => {
            if (params.row.Isquotedprice == 1) {
              return [
                h("div", "￥" + params.row.Price),
                h("div", params.row.QuotedPriceTime.substring(0, 10)),
              ];
            } else {
              return h("span", "未报价");
            }
          },
        },
        {
          title: "操作",
          width: 80,
          align: "right",
          render: (h, params) => {
            let a = [];
            a.push(
              h("Icon", {
                props: { type: this.$able.map.CrmOrderUpd ? "md-create" : "md-open", size: 18 },
                attrs: { title: this.$able.map.CrmOrderUpd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.CrmOrderDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.edit.flag) {
        this.$refs.edit.close()
      }
      this.initSearch()
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.CrmOrderQry == undefined) {
        this.$Message.error("无权限查询")
        return
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/CrmOrder/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 新增
    toAdd() {
      this.$refs.edit.open();
    },
    // 删除
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + row.OrderCode,
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/CrmOrder/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
