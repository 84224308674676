<template>
  <div>
    <Drawer
      :mask-closable="false"
      :title="id ? '查看' : '新增'"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer crm-order-drawer"
      v-model="flag"
      width="700"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          :label-width="130"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="状态：" prop="Status" required>
            <Select v-if="ABLE" v-model="formd.Status">
              <Option
                :key="i"
                :value="a.key"
                v-for="(a, i) in $designConst.crmOrder.StatusLst"
              >{{ a.name }}</Option>
            </Select>
            <div v-else>{{$designConst.crmOrder.Status[formd.Status]}}</div>
          </FormItem>
          <FormItem label="业务员：" prop="SalesId" required>
            <Input :value="formd.SalesName" @on-clear="unPickUser" clearable readonly v-if="ABLE">
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.SalesName}}</div>
          </FormItem>
          <FormItem label="客户：" prop="ClientId" required>
            <Input
              :value="formd.ClientName"
              @on-clear="unPickTrader"
              clearable
              readonly
              v-if="ABLE"
            >
              <Button @click="toPickTrader" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.ClientName}}</div>
          </FormItem>
          <FormItem label="单据编号：">
            <Input placeholder="默认由系统生成" v-if="ABLE" v-model="formd.OrderCode" />
            <div v-else>{{formd.OrderCode}}</div>
          </FormItem>
          <FormItem label="跟单开始日期：">
            <DatePicker v-if="ABLE" v-model="formd.OrderDate" />
            <div v-else>{{formd.OrderDate}}</div>
          </FormItem>
          <FormItem label="合同编号：">
            <Input v-if="ABLE" v-model="formd.ContractCode" />
            <div v-else>{{formd.ContractCode}}</div>
          </FormItem>
          <FormItem label="项目评估：" prop="EvaluateTime">
            <i-switch
              :false-value="0"
              :true-value="1"
              size="large"
              v-if="ABLE"
              v-model="formd.Isevaluate"
            >
              <span slot="open">已评估</span>
              <span slot="close">未评估</span>
            </i-switch>
            <DatePicker
              :disabled="formd.Isevaluate == 0"
              placeholder="评估日期"
              style="width: 120px; margin-left: 9px"
              v-if="ABLE"
              v-model="formd.EvaluateTime"
            ></DatePicker>
            <div v-else>
              {{formd.Isevaluate==1?"已评估":"未评估"}}
              <div v-if="formd.Isevaluate==1" class="text-sub text-xs">{{formd.EvaluateTime}}</div>
            </div>
          </FormItem>
          <FormItem label="报价：" prop="QuotedPriceTime">
            <i-switch
              :false-value="0"
              :true-value="1"
              size="large"
              v-if="ABLE"
              v-model="formd.Isquotedprice"
            >
              <span slot="open">已报价</span>
              <span slot="close">未报价</span>
            </i-switch>
            <DatePicker
              :disabled="formd.Isquotedprice == 0"
              placeholder="报价时间"
              style="width: 120px; margin-left: 9px"
              v-if="ABLE"
              v-model="formd.QuotedPriceTime"
            ></DatePicker>
            <div
              v-else
            >
              {{formd.Isquotedprice==1?"已报价：￥"+formd.Price:"未报价"}}
              <div v-if="formd.Isevaluate==1" class="text-sub text-xs">{{formd.QuotedPriceTime}}</div>
            </div>
          </FormItem>
          <FormItem label="报价金额：" prop="Price" v-show="ABLE">
            <Input
              :disabled="formd.Isquotedprice == 0"
              placeholder="可保留2位小数"
              v-if="ABLE"
              v-model="formd.Price"
            >
              <span slot="append">￥</span>
            </Input>
          </FormItem>
          <!-- <FormItem label="报价表上传：">
            <div class="text-sub" v-if="id == 0">新增后可上传</div>
            <div v-if="id > 0">
              <span class="text-sub" v-if="formd.PriceUserId == 0">暂未上传</span>
              <span v-else>{{ formd.PriceUserName }}</span>
            </div>
          </FormItem>-->
          <FormItem class="long" label="需求描述：" prop="Description" required>
            <Input type="textarea" v-if="ABLE" v-model="formd.Description" />
            <div v-else>{{formd.Description}}</div>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
          <FormItem class="long" label="报价附件：" v-if="id > 0">
            <input @change="onFiles" multiple ref="pickFile" style="display: none" type="file" />
            <Button
              @click="
                () => {
                  $refs.pickFile.click();
                }
              "
              size="small"
              type="primary"
              v-if="ABLE && $refs.pickFile"
            >选择文件</Button>
            <ul class="files">
              <Spin class="bk-loading" fix v-if="attaLoading!=0">
                <div>
                  <Icon type="ios-loading"></Icon>
                  <div v-if="attaLoading==-1">删除中...</div>
                  <div v-if="attaLoading==1">加载中...</div>
                  <div v-if="attaLoading==2">上传中...</div>
                </div>
              </Spin>
              <li :key="`attas-${i}`" v-for="(a, i) in attachLst">
                <span class="text-primary">{{ a.Name }}</span>
                <Icon
                  @click="removeAttach(i)"
                  color="#ed4014"
                  size="18"
                  style="cursor: pointer"
                  type="md-close"
                  v-if="ABLE"
                ></Icon>
                <div class="text-sub text-xs">{{a.CreateUserName}}/{{a.CreateTime}}</div>
              </li>
            </ul>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Drawer>
    <pick-user @on-ok="onPickUser" ref="PickUser" title="选择业务员" type="1" />
    <pick-trader :type="1" @on-ok="onPickTrader" ref="PickTrader" title="选择客户" />
  </div>
</template>
<script>
import PickUser from "../../components/PickUser.vue";
import PickTrader from "../../components/PickTrader.vue";
export default {
  components: { PickUser, PickTrader },
  data() {
    return {
      ABLE: false,
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: { Isevaluate: 0, Isquotedprice: 0 },
      rules: {
        Status: [{ required: true, type: "number", message: " " }],
        SalesId: [{ required: true, type: "number", message: " " }],
        ClientId: [{ required: true, type: "number", message: " " }],
        EvaluateTime: [
          {
            validator: (rule, value, callback) => {
              if (this.formd.Isevaluate == 1) {
                this.formd.EvaluateTime ? callback() : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        QuotedPriceTime: [
          {
            validator: (rule, value, callback) => {
              if (this.formd.Isquotedprice == 1) {
                this.formd.QuotedPriceTime
                  ? callback()
                  : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        Price: [
          {
            validator: (rule, value, callback) => {
              if (this.formd.Isquotedprice == 1) {
                if (this.formd.Price) {
                  /^\d+(\.\d{1,2})?$/.test(this.formd.Price)
                    ? callback()
                    : callback(new Error(" "));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
          },
        ],
        Description: [{ required: true, message: " " }],
      },
      attachLst: [],
      attaLoading: 0,
    };
  },
  methods: {
    open(id) {
      if (id) {
        this.ABLE = this.$able.map.CrmOrderUpd;
        this.id = id;
        this.req();
        this.reqAttach();
      } else {
        this.ABLE = this.$able.map.CrmOrderAdd;
      }
      this.flag = true;
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = { Isevaluate: 0, Isquotedprice: 0 };
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/CrmOrder/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    postData() {
      let api;
      if (this.id > 0) {
        api = "/Api/CrmOrder/Update";
      } else {
        api = "/Api/CrmOrder/Add";
      }
      let param = this.formd;
      this.posting = true;
      this.$axios
        .post(api, param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("编辑成功");
              this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
            }
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择人员
    toPickUser() {
      if (this.formd.SalesId) {
        this.$refs.PickUser.open([
          { Id: this.formd.SalesId, UserName: this.formd.SalesName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.SalesId = lst[0].Id;
        this.$set(this.formd, "SalesName", lst[0].UserName);
      } else {
        this.formd.SalesId = "";
        this.$set(this.formd, "SalesName", "");
      }
    },
    unPickUser(i) {
      this.formd.SalesId = "";
      this.$set(this.formd, "SalesName", "");
    },
    // 选择客户
    toPickTrader() {
      if (this.formd.ClientId) {
        this.$refs.PickTrader.open([
          {
            Id: this.formd.ClientId,
            ShortName: this.formd.ClientName,
          },
        ]);
      } else {
        this.$refs.PickTrader.open();
      }
    },
    onPickTrader(lst) {
      if (lst[0]) {
        this.formd.ClientId = lst[0].Id;
        this.$set(this.formd, "ClientName", lst[0].ShortName);
      }
    },
    unPickTrader() {
      this.formd.ClientId = "";
      this.$set(this.formd, "ClientName", "");
    },
    // 附件
    reqAttach() {
      this.attaLoading = 1;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 5,
            DataId: this.id,
          },
        })
        .then((res) => {
          this.attaLoading = 0;
          if (res.result) {
            this.attachLst = res.data;
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attaLoading = 0;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    removeAttach(i) {
      this.attaLoading = -1;
      this.$axios
        .post("/Api/DataAttachment/Delete", { Ids: this.attachLst[i].Id })
        .then((res) => {
          if (res.result) {
            this.reqAttach();
          } else {
            this.attaLoading = 0;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attaLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
    // 报价附件
    onFiles(e) {
      if (e.target.files.length > 0) {
        let files = [];
        for (let i = 0; i < e.target.files.length; i++) {
          files.push(e.target.files[i]);
        }
        // this.files = this.files.concat(files);
        e.target.value = "";
        this.postFiles(files);
      }
    },
    postFiles(files) {
      let formdata = new FormData();
      formdata.append("OrderId", this.orderid);
      formdata.append("DataId", this.id);
      formdata.append("Type", 5);
      for (let a of files) {
        formdata.append("File", a);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.attaLoading = 2;
      this.$axios
        .post("/File/PostObject", formdata, config)
        .then((res) => {
          this.attaLoading = 0;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttach();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attaLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.crm-order-drawer {
  .ivu-form-item {
    width: 50%;
    margin: 0 0 10px;
  }
  .ivu-form-item.long {
    width: 100%;
  }
  .ivu-switch-large {
    width: 72px;
  }
  .ivu-switch-large.ivu-switch-checked:after {
    left: 51px;
  }
  .files {
    position: relative;
  }
  .files li {
    // display: flex;
    // align-items: center;
  }
  .files li .ivu-icon {
    margin-left: 10px;
  }
}
</style>